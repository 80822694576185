<!--收费标准-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <el-row class="outerRow">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <el-row class="outerRow">
      <el-col class="rowSpaceBetween">
        <!--   左侧菜单栏   -->
        <div class="leftDiv">
          <left-menu :menu-list="leftMenu" @menuActive="clickMenu"></left-menu>
        </div>

        <!--   右侧区域   -->
        <div class="rightDiv">
          <div class="rulesDiv">
            <div class="rulesTitle">
              {{menuName}}
            </div>
            <div class="line-class"></div>
            <div style="width: 100%; margin-bottom: 20px"></div>
            <div class="w-e-text-container">
              <div v-html="content[menuIndex]" data-slate-editor></div>
            </div>
          </div>
          <!--增加取消下方白条-->
          <div style="height: 20px; background-color: #f8f9fa;"></div>
          <el-row v-show="fileList && fileList.length > 0">
            <el-col class="label_contain">
              <div class="fileLabelDiv">附件：</div>
              <div>
                <div v-for="(item, index) in fileList" :key="index" class="fileDiv">
                  <span class="fileSpanDiv cursorPointer" @click="downloadFile(item)">{{item.fileName}}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import { giEdificeList } from '@/utils/commonApi'
import leftMenu from '@/components/leftMenu'
import axios from 'axios'
import { downLoadFile } from '@/utils/commonApi'
export default {
  name: 'chargingStandards',
  components: { topPathList, leftMenu },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标大厦',
          path: '/giEdifice/index'
        },
        {
          name: '收费标准',
          path: '/giEdifice/chargingStandards'
        },
      ],
      content: [],
      leftMenu: [],
      menuIndex: 0,
      menuName: '',
      fileList: [], //附件
      resData: [], //用于储存接口返回数据
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    clickMenu({ menu, index }) {
      this.menuName = menu.name;
      this.menuIndex = index
      //获取对应点击到的菜单的附件
      if (this.resData[index].file != "") {
        let file = this.resData[index].file
        let param = {
          fileIds: file
        }
        axios({
          method: "get",
          url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/attachment/uploadFilesQuery',
          params: param
        }).then(res => {
          if (res.data.code === 10000 || res.data.code === 200) {
            this.fileList = res.data.data
          } else {
            this.$message.warning("附件请求异常")
          }
        }).catch(err => {
          this.$message.error('附件请求失败')
        })
      } else {
        this.fileList = []
      }
    },
    initData() {
      let params = {
        moduleName: '3'
      }
      giEdificeList(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let data = res.data.data;
          this.resData = res.data.data;
          if (data && data.length > 0) {
            data.forEach(item => {
              this.leftMenu.push({ name: item.typeName });
              this.content.push(item.articleInfo)
            })

            //第1个菜单的附件
            if (data[0].file != "") {
              let firstFile = data[0].file
              let param = {
                fileIds: firstFile
              }
              axios({
                method: "get",
                url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/attachment/uploadFilesQuery',
                params: param
              }).then(res => {
                if (res.data.code === 10000 || res.data.code === 200) {
                  this.fileList = res.data.data
                } else {
                  this.$message.warning("附件请求异常")
                }
              }).catch(err => {
                this.$message.error('附件请求失败')
              })
            } else {
              this.fileList = []
            }
            this.menuName = this.leftMenu[0].name;
            this.$forceUpdate()
          }
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //附件下载
    downloadFile(file) {
      let fileInfo = {
        fileId: file.id,
        fileName: file.fileName
      }
      downLoadFile(fileInfo)
    }

  }
}
</script>

<style scoped lang="less">
@import '~@/assets/css/common.less';
::v-deep .w-e-text-container img {
  max-width: 100% !important;
}
.main-div {
  background-color: #f8f9fa !important;
}
.leftDiv {
  width: 15%;
  text-align: center;
}
.rightDiv {
  width: 82%;
  background: white;
  border-radius: 5px;
  margin-bottom: 40px;
}
.rulesDiv {
  transition: all .2s ease;
  background: #FFFFFF;
  box-shadow: 0 3px 12px 0 rgba(178, 178, 178, 0.26);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 23px 24px;
  min-height: 517px;

  .rulesTitle {
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #1A5BC5;
    line-height: 30px;
    text-align: left;
  }

  .line-class {
    margin-top: 20px;
    width: 910px;
    border: 1px solid #EBEFF6;
  }
}
.label_contain {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
}
.fileLabelDiv {
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  color: #3E3A39;
  line-height: 24px;
  align-self: flex-start;
}
.fileDiv {
  text-align: left;
  .fileSpanDiv {
    padding: 5px 10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #186CF5;
    line-height: 28px;
  }
}
</style>
